
export const minHeightForMenuPage = (mobile) => mobile ? 500 : 700;
export const paddingForMenuPage = (mobile) => mobile ? "1em" : "2em";

export const chartTitleTextStyle = (mobile) =>
    ({ textAlign: "center", fontWeight: "normal", fontSize : (mobile) ? "1.5em" : "1.7em" });

export const vizBackgroundStyle = (mobile) =>
    ({ width: mobile ? "90%" : "60%", margin: "auto", marginBottom: "10px" });

export const AnimalsRedColor = "#A35245";
export const PlantsGreenColor = "#238b45";
