import React from "react"
import { navigate } from "gatsby"
import SEO from "../components/seo"
import { minHeightForMenuPage, paddingForMenuPage } from "../defines";
import {
    List,
    Segment
} from "semantic-ui-react"

const nav = (path) => {
    navigate(path, { state: { category : "health" } });
}

const HealthPage = ({ mobile }) => (
    <>
        <SEO title="Health" />
        <Segment
            textAlign="center"
            vertical
            inverted
            style={{ minHeight: minHeightForMenuPage(mobile), padding: paddingForMenuPage(mobile) }}
        >
            <List divided inverted relaxed="very">
                <List.Item as="a" onClick={() => nav("/visualizations/health/lactose-intolerance")}>
                    <List.Content>
                        <List.Header>Lactose intolerance map</List.Header>
                        <List.Description>Per country map showing lactose intolerance</List.Description>
                    </List.Content>
                </List.Item>
                <List.Item as="a" onClick={() => nav("/visualizations/health/cardiovascular-disease-markers")}>
                    <List.Content>
                        <List.Header>Cardiovascular disease markers</List.Header>
                        <List.Description>Comparison of cardiovascular disease markers across various diets</List.Description>
                    </List.Content>
                </List.Item>
            </List>
        </Segment>
    </>
)

export default HealthPage
